import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledSignupCoreComponent = styled.div`
  .additional-button-text {
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 18px;
    color: ${Colors.asphalt};

    &.below {
      margin-top: 8px;
      text-align: center;
    }

    &.side {
      margin-left: 16px;
      text-align: left;
    }
  }

  .signup-button-wrapper {
    &.additional-text {
      display: flex;
      align-items: center;

      &.additional-text-below {
        flex-direction: column;
      }

      &.additional-text-side {
        flex-direction: row;
      }
    }

    &.hide-button {
      visibility: hidden;
    }

    &.with-icon {
      .ladda-button-component {
        .ladda-label {
          display: flex;
          align-items: center;

          .product-icon {
            display: flex;
            margin-right: 8px;
            height: fit-content;
          }
        }
      }
    }

    &.with-experimental-animation {
      button {
        position: relative;
        z-index: 1;
        overflow: unset;
        transform-style: preserve-3D;
      }

      button::before {
        content: "";
        position: absolute;
        top: -5%;
        left: -3%;
        width: 106%;
        height: 110%;
        z-index: -1;
        border-radius: 100px; //for pill shape
        transform: translateZ(-1px);
        background: linear-gradient(
          to top left,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 45%,
          rgba(255, 255, 255, 0.5) 48%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0.5) 52%,
          rgba(255, 255, 255, 0) 57%,
          rgba(255, 255, 255, 0) 100%
        );
        background-size: 600px 600px, 100% 100%;
        background-repeat: no-repeat;
        background-position: 100% 100%, center;
        animation: shine 4s linear infinite;
        filter: blur(2px);
      }

      @keyframes shine {
        to {
          background-position: -100% -100%, center;
        }
      }
    }
  }

  &.vertical-google-connect {
    display: flex;
    flex-direction: column-reverse;
    min-width: 305px;

    .social-connect-section {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;

      .google-separator {
        display: flex;
        align-items: center;
        margin: 16px 0;

        .seperator-line {
          flex-grow: 1;
          border-color: ${Colors["wolf-gray"]};
          border-top-width: 0.5px;
          border-bottom-width: 0.5px;
          border-style: solid;
          opacity: 0.4;
        }

        .separator-text {
          padding-left: 16px;
          padding-right: 16px;
          font-size: 0.8125rem;
          line-height: 24px;
          color: ${Colors.asphalt};
        }
      }

      .google-connect-button {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;

        .google-button-inner {
          display: flex;
          justify-content: center;

          .continue-text {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
          }

          .google-icon {
            width: 24px;
            height: 24px;
            margin-left: 16px;
          }
        }
      }
    }
  }

  .terms-of-service {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 240px;
    font-size: 0.75rem;
    align-self: center;
    font-weight: 400;
    line-height: 1.125rem;

    .link {
      color: inherit;
    }
  }

  &.dark-theme {
    .additional-button-text {
      color: ${Colors.white};
    }

    .social-connect-section {
      .google-separator {
        .separator-text {
          color: ${Colors.white};
        }
      }
    }

    .google-connect-button {
      border: none;
    }
  }
`;
